import request from '@/utils/request'
import QS from 'qs'
export default {
  //获取仓库列表
  getListStashSku(query) {
    return request({
      url: "/mapi",
      method: "post",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: QS.stringify({
        _gp: 'stashSku',
        _mt: 'listStashSku',
        ...query
      })
    });
  }
}