import axios from "axios";
import router from "../router";
import { Toast, Notify } from "vant";
import { getToken } from "@/utils/auth";
import errorCode from "@/utils/errorCode";

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  router.replace({
    path: '/login',
    query: {
      redirect: router.currentRoute.fullPath
    }
  })
}

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: 'http://120.77.11.127:8082',
  // baseURL: "http://192.168.31.79:8089",//蒲总
  // baseURL: "http://192.168.31.24:8089",//汪哥

 
  // 超时
  timeout: 30000
});
// request拦截器
service.interceptors.request.use(
  config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    if (getToken() && !isToken) {
      // config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
      config.headers["ACCESSTOKEN"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
      // config.headers["X-Forwarded-For"] = window.location.hostname
    }
    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?";
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof value !== "undefined") {
          if (typeof value === "object") {
            for (const key of Object.keys(value)) {
              let params = propName + "[" + key + "]";
              var subPart = encodeURIComponent(params) + "=";
              url += subPart + encodeURIComponent(value[key]) + "&";
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  error => {
    // console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    // console.log('response.data', response);
    if (res.errno === 10001) {
      Toast({
        message: "系统未登录，请重新登录",
        duration: 1500,
        forbidClick: true
      });
      toLogin()
      return Promise.reject('error')
    } 
    if (res.errno === 10000) {
      Toast({
        message: res.errmsg,
        duration: 1500,
        forbidClick: true
      });
      return Promise.reject('error')
    } 
    if (res.errno === 13000) {
      Toast({
        message: res.errmsg,
        duration: 1500,
        forbidClick: true
      });
      return Promise.reject('error')
    } 
    // if (res.errno != 200) {
    //   Toast({
    //     message: res.errmsg,
    //     type: 'error',
    //     duration: 5 * 1000
    //   })
    //   // return Promise.reject('error')
    // } else {
    //   // return response
    // }
    return response.data;
    // 未设置状态码则默认成功状态
    // const code = res.data.errno || 200;
    // debugger
    // 获取错误信息
    // const msg = errorCode[code] || res.data.msg || errorCode["default"];

    // if (code === 401) {
    //   Toast({
    //     message: "登录过期，请前往'我的'重新登录",
    //     duration: 1000
    //   })
    //   setInterval(() => {
    //     toLogin()
    //   }, 1000)

    // } else if (code === 500) {
    //   Toast({
    //     message: msg,
    //     type: "fail"
    //   });
    //   return Promise.reject(new Error(msg));
    // } else if (code !== 100) {
    //   Notify({ type: 'danger', message: msg })
    //   return Promise.reject("error");
    // } else {
    //   return res.data;
    // }

    

    // if (code === 401) {
    //   Toast({
    //     message: "登录过期，请前往'我的'重新登录",
    //     duration: 1000
    //   })
    //   setInterval(() => {
    //     toLogin()
    //   }, 1000)

    // } else if (code === 500) {
    //   Toast({
    //     message: msg,
    //     type: "fail"
    //   });
    //   return Promise.reject(new Error(msg));
    // } else if (code !== 100) {
    //   Notify({ type: 'danger', message: msg })
    //   return Promise.reject("error");
    // } else {
    //   return res.data;
    // }
  },
  error => {
    // console.log("err" + error);
    let { message } = error;
    if (message == "Network Error") {
      message = "服务器连接异常";
    } else if (message.includes("timeout")) {
      message = "服务器请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "服务器" + message.substr(message.length - 3) + "异常";
    }
    Toast({
      message: message,
      type: "fail",
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);

export default service;
