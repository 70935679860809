import request from '@/utils/request'
import QS from 'qs'
import {
    postUpload
  } from '@/utils/request';


let fileUploadUrl = "/upload/admin";


export default {
    /**
     * 一级分类tab
     * @param {*} param
     */
    //  ownDetailAddress(param) {
    //     return request({
    //         url: "/core/api/sales/Customer_displayDetail",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },

    // ownDetailAddress(query) {
    //         return request({
    //             url: "/mapi",
    //             method: "post",
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded'
    //             },
    //             data: QS.stringify({
    //                 _gp: 'certificate',
    //                 _mt: 'getCertificateInfo',
    //                 ...query
    //             }) 
    //         });
    //     },

      ownDetailAddress(query) {
            return request({
                url: "/mapi",
                method: "post",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: QS.stringify({
                    _gp: 'user',
                    _mt: 'displayDetail',
                    ...query
                }) 
            });
        },


        
        fileUploadAddress(fileUploadobj) {
            return postUpload(fileUploadUrl, fileUploadobj);
          },


    /**
     * 二级分类tab
     * @param {*} param
     */
     loginAddress(param) {
        return request({
            url: "/core/system/Login_customerRegisterAndLoginDhmall",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify(param)
        });
    },


     /**
     *获取所有的收货地址
     * @param {*} param
     */
    // addressListAddress(param) {
    //     return request({
    //         url: "/core/api/customer/CustomerAddress_addressList",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },

    addressListAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'address',
                _mt: 'getAllAddress',
                ...query
            }) 
        });
    },


    



    //获取地址详情回显
    // readAddressSingle(param) {
    //     return request({
    //         url: "/core/api/customer/CustomerAddress_loadCustomerAddress",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },

    readAddressSingle(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'address',
                _mt: 'getAddressById',
                ...query
            }) 
        });
    },

    //获取星联地址

    getAllRegion(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'address',
                _mt: 'getAllRegion',
                ...query
            }) 
        });
    },

    //修改地址

    
    changeAddressListAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'address',
                _mt: 'updateAddress',
                ...query
            }) 
        });
    },

    //新增收货地址

        
    
    addAddressListAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'address',
                _mt: 'addAddress',
                ...query
            }) 
        });
    },

    //删除收货地址

    
      
    delAddressListAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'address',
                _mt: 'deleteAddress',
                ...query
            }) 
        });
    },

    //获取用户订单
    initOrderListAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'order',
                _mt: 'getOrderPage',
                ...query
            }) 
        });
    },

    //发布评论
    
    addCommentAddress(userId,param) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'appraise',
                _mt: 'addAppraise',
                userId:userId,
                appraiseRequestDTO : param,
                // orderId:orderId,

            }) 
        });
    },

    //订单详情

    orderSureAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'order',
                _mt: 'getOrderDetail',
                ...query
            }) 
        });
    },
    //获取订单物流信息
    getOrderDelivery(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'order',
                _mt: 'getOrderDelivery',
                ...query
            }) 
        });
    },

    //获取物流
    translist(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'delivery',
                _mt: 'translist',
                ...query
            }) 
        });
    },

    //取消订单

    cancelOrderAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'order',
                _mt: 'cancel',
                ...query
            }) 
        });
    },

    //订单退款
    
    payRefundOrderAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'order',
                _mt: 'refund',
                ...query
            }) 
        });
    },

    //确认订单
    orderFinishAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'order',
                _mt: 'confirm',
                ...query
            }) 
        });
    },

    //获取收藏
    

    collectGoodsAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'collect',
                _mt: 'getCollectAll',
                ...query
            }) 
        });
    },

    //删除收藏

    newcollectAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'collect',
                _mt: 'deleteCollect',
                ...query
            }) 
        });
    },


    //获取足迹

    footPrintAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'footprint',
                _mt: 'getAllFootprint',
                ...query
            }) 
        });
    },

    //删除所有足迹
    
    delFootPrintAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'footprint',
                _mt: 'deleteFootprint',
                ...query
            }) 
        });
    },


    //获取用户的所有优惠卷
    
    showCouponAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'coupon',
                _mt: 'getAllUserCoupons',
                ...query
            }) 
        });
    },

    //兑换优惠卷
    

    exchangeCouponAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'coupon',
                _mt: 'obtainCoupon',
                ...query
            }) 
        });
    },


    //用户修改昵称和头像
    userEdit(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'user',
                _mt: 'edit',
                ...query
            }) 
        });
    },




    // /**
    //  * 猜你喜欢
    //  */
    // guessYouLikeAddress(query) {
    //     return request({
    //         url: "/mapi",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify({
    //             _gp: 'goods',
    //             _mt: 'guessYouLike',
    //             ...query
    //         }) 
    //     });
    // },


    // 提交实名信息
    insertOrUpdate(query){
        return request({
            url:"/mapi",
            method: "post",
            headers :{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data:QS.stringify({
                _gp: "certificate",
                _mt: "insertOrUpdate",
                ...query
            })
        })
    },
    // 查询实名认证信息
    getCertificateInfo(query){
        return request({
            url:"/mapi",
            method: "post",
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data:QS.stringify({
                _gp: "certificate",
                _mt: "getCertificateInfo",
                ...query
            })
        })
    },
    // 上传图片
    createStorag(query){
        return request({
            url:"/mapi",
            method: "post",
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data:QS.stringify({
                _gp: "upload",
                _mt: "admin",
                ...query
            })
        })
    },
    getHelpCenterList(query){
        return request({
            url:"/mapi",
            method: "post",
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data:QS.stringify({
                _gp: "helpCenter",
                _mt: "getHelpCenterList",
                ...query
            })
        })
    },
    adminByBase64(query){
        return request({
            // url:"/mapi",
            url:'/upload/admin',
            method: "post",
           
            file:query
            
        })
    },
    // 补开发票
    RepairInvoice(query){
        return request({
            url:"/mapi",
            method:"post",
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data:QS.stringify({
                _gp: "order",
                _mt: "RepairInvoice",
                ...query
            })
        })
    },
    checkInvoice(query){
        return request({
            url:"/mapi",
            method:"post",
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data:QS.stringify({
                _gp: "order",
                _mt: "checkInvoice",
                ...query
            })
        })
    },
    // 更新我的发票管理
    insetOrUpdate(query){
        return request({
            url:"/mapi",
            method:"post",
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data:QS.stringify({
                _gp: "invoiceUser",
                _mt: "insetOrUpdate",
                ...query
            })
        })
    },
    // 获取我的发票信息列表
    getInvoiceUserList(query){
        return request({
            url:"/mapi",
            method:"post",
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data:QS.stringify({
                _gp: "invoiceUser",
                _mt: "getInvoiceUserList",
                ...query
            })
        })
    },
    // 删除发票管理
    delete(query){
        return request({
            url:"/mapi",
            method:"post",
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data:QS.stringify({
                _gp: "invoiceUser",
                _mt: "delete",
                ...query
            })
        })
    },
}


