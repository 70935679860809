import Vue from 'vue'
import Router from 'vue-router'
import { Toast } from "vant";
Vue.use(Router)
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
const routes = [
  // 重定向路由
  {
    path: "/",
    redirect: "/index"
  },
  // 首页
  {
    name: "index",
    path: '/index',
    component: (resolve) => require(['@/views/Index/index'], resolve)
  },
  //下载页
  {
    name: "update",
    path: '/update',
    component: (resolve) => require(['@/views/Index/update'], resolve)
  },
  //单品首页
  {
    name: "indexAlone",
    path: '/indexAlone',
    component: (resolve) => require(['@/views/Index/indexAlone'], resolve)
  },
  // 我的
  {
    name: "my",
    path: '/my',
    component: (resolve) => require(['@/views/my/index'], resolve),
    meta: {
      requireAuth: true
    },
  
  },
  //我的订单
  {
    path: "/myorder",
    name: "myorder",
    component: (resolve) => require(['@/views/my/children/myorder'], resolve),
  
},
// 订单详情
{
  path: "/orderdetail/:orderNo",
  name: "orderdetail",
  component: (resolve) => require(['@/views/orderdetail/index'], resolve),
},
// 物流列表
{
  path: "/logisticsDetails",
  name: "logisticsDetails",
  component: (resolve) => require(['@/views/orderdetail/logisticsDetails'], resolve),
},
// 去退货
{
  path: "/salesReturn/:orderNo",
  name: "salesReturn",
  component: (resolve) => require(['@/views/orderdetail/salesReturn'], resolve),
},
//物流信息
{
  path: "/logistics",
  name: "logistics",
  component: (resolve) => require(['@/views/transport/index'], resolve),
},
//评论

{
  path: "/addComment",
  name: "addComment",
  component: (resolve) => require(['@/views/my/children/addComment'], resolve),

},

// 补开发票
{
  path: "/repairInvoice",
  name: "repairInvoice",
  component: (resolve) => require(['@/views/my/children/repairInvoice'], resolve),

},

// 查看发票
{
  path: "/viewInvoice",
  name: "viewInvoice",
  component: (resolve) => require(['@/views/my/children/viewInvoice'], resolve),
  
},
// 发票抬头管理
{
  path: "/controlOverInvoices",
  name: "controlOverInvoices",
  component: (resolve) => require(['@/views/my/children/controlOverInvoices'], resolve),
  
},

// 添加发票抬头
{
  path: "/addInvoiceHeader",
  name: "addInvoiceHeader",
  component: (resolve) => require(['@/views/my/children/addInvoiceHeader'], resolve),
  
},
// 删除发票管理
{
  path: "/deleteInvoiceManagement",
  name: "deleteInvoiceManagement",
  component: (resolve) => require(['@/views/my/children/deleteInvoiceManagement'], resolve),
  
},
// 分销员申请
{
  path: "/distributorApply",
  name: "distributorApply",
  component: (resolve) => require(['@/views/distributorApply'], resolve),
  meta: {
    requireAuth: true,
  }
},
// 邀请成为二级分销员
{
  path: "/inviteDistributor",
  name: "inviteDistributor",
  component: (resolve) => require(['@/views/inviteDistributor'], resolve),
  
},
// 获取分销员信息
{
  path: "/distributorInfo",
  name: "distributorInfo",
  component: (resolve) => require(['@/views/distributorInfo'], resolve),
  
},
// 获取分销员分享出去的订单数据
{
  path: "/distributorOrder",
  name: "distributorOrder",
  component: (resolve) => require(['@/views/distributorInfo/distributorOrder'], resolve),
  
},
// 发票抬头管理
{
  path: "/controlOverInvoices",
  name: "controlOverInvoices",
  component: (resolve) => require(['@/views/my/children/controlOverInvoices'], resolve),
  
},
//收藏
{
  path: "/collect",
  name: "collect",
  component: (resolve) => require(['@/views/my/children/collect'], resolve),

},
//足迹
{
  path: "/footprint",
  name: "footprint",
  component: (resolve) => require(['@/views/my/children/footprint'], resolve),

},


    //地址详情
    {
      path: "/addresslist",
      name: "addresslist",
      component: (resolve) => require(['@/views/my/children/addresslist'], resolve),
    
  },

  //地址新增和编辑
  {
    path: "/editaddress",
    name: "editaddress",
    component: (resolve) => require(['@/views/my/children/editaddress'], resolve),
  
},

//我的优惠卷
{
  path: "/coupon",
  name: "coupon",
  component: (resolve) => require(['@/views/my/children/coupon'], resolve),

},
 // 修改用户名
 {
  name:"changename",
  path:"/changename",
  component: (resolve) => require(['@/views/my/children/changename'],resolve)
},    
 // 修改头像
 {
  name:"information",
  path:"/information",
  component: (resolve) => require(['@/views/my/children/information'],resolve)
},  
//修改性别
{
  name:"sex",
  path:"/sex",
  component: (resolve) => require(['@/views/my/children/sex'],resolve)
},  
//修改昵称
{
  name:"idNumber",
  path:"/idNumber",
  component: (resolve) => require(['@/views/my/children/idNumber'],resolve)
},  

 // 帮助中心
 {
  name:"helpcenter",
  path:"/helpcenter",
  component: (resolve) => require(['@/views/my/children/helpCenter'],resolve)
},    
 // 帮助中心
 {
  name:"helpCenterDetails",
  path:"/helpCenterDetails",
  component: (resolve) => require(['@/views/my/children/helpCenterDetails'],resolve)
},    
//修改手机
{
  path: "/changetel",
  name: "changetel",
  component: (resolve) => require(['@/views/my/children/changetel'], resolve),

},

//关于云楚新能源
{
  path: "/aboutUs",
  name: "aboutUs",
  component: (resolve) => require(['@/views/my/children/aboutUs'], resolve),

},

//服务协议
{
  path: "/agree",
  name: "agree",
  component: (resolve) => require(['@/views/login/agree'], resolve),

},

//隐私政策
{
  path: "/privacypolicy",
  name: "privacypolicy",
  component: (resolve) => require(['@/views/privacypolicy/privacypolicy'], resolve),

},

  // 分类
  {
    name: "classfication",
    path: "/classfication",
    component: (resolve) => require(['@/views/classfication/index'], resolve)
  },
  //食堂分类
  {
    name: "classficationFood",
    path: "/classficationFood",
    component: (resolve) => require(['@/views/classficationFood/index'], resolve)
  },
  // 购物车
  {
    name: "shoppingcar",
    path: "/shoppingcar",
    component: (resolve) => require(['@/views/shoppingcar/index'], resolve),
    // 需要登录才能进入的页面可以增加一个meta属性
    meta: {
      requireAuth: true
    }
  },

//确认购买
{
  name: "orderconfirm",
  path: "/orderconfirm",
  component: (resolve) => require(['@/views/orderconfirm/index'], resolve),
  // 需要登录才能进入的页面可以增加一个meta属性
  meta: {
    // requireAuth: true
  }
},
  // 登录
  {
    name: "login",
    path: "/login",
    component: (resolve) => require(['@/views/login/login copy'], resolve),
    // 需要登录才能进入的页面可以增加一个meta属性
    meta: {
      keepAlive: true,
      isUseCache: false
    }
  },
  {
    name: "isDeHe",
    path: "/isDeHe",
    component: (resolve) => require(['@/views/Index/isDeHe'], resolve),
    // 需要登录才能进入的页面可以增加一个meta属性
    meta: {
      keepAlive: true,
      isUseCache: false
    }
  },
   // 微信登录
   {
    name: "wxlogin",
    path: "/wxlogin",
    component: (resolve) => require(['@/views/login/wxlogin'], resolve),
    // 需要登录才能进入的页面可以增加一个meta属性
    meta: {
      keepAlive: true,
      isUseCache: false
    }
  },
  // 搜索
  {
    name: "search",
    path: "/search",
    component: (resolve) => require(['@/views/search/index'], resolve),
    // 需要登录才能进入的页面可以增加一个meta属性
    meta: {
      keepAlive: true,
      isUseCache: false
    }
  },
  // 搜索
  {
    name: "secondclass",
    path: "/secondclass/:id/",
    component: (resolve) => require(['@/views/secondclassfication/index'], resolve),
    // 需要登录才能进入的页面可以增加一个meta属性
    // meta: {
    //   keepAlive: true,
    //   isUseCache: false
    // },
    children: [
      {
        path: "goods/:categoryId",
        name: "goods",
        component: (resolve) => require(['@/views/secondclassfication/children/goods'], resolve),
      }
    ]
  },
  // 文章详情
  {
    name: "articledetail",
    path: "/articledetail/:id",
    component: (resolve) => require(['@/views/articledetail/index'], resolve),
  },
  // type商品列表
  {
    name: "categoryImgGoods",
    path: "/categoryImgGoods/:categoryType",
    component: (resolve) => require(['@/views/categoryImgGoods/index'], resolve),
  },
  // 特色馆二级界面
  {
    name: "uniqueFeatureSecond",
    path: "/uniqueFeatureSecond/:categoryId",
    component: (resolve) => require(['@/views/uniqueFeatureSecond/index'], resolve),
  },
  // 商品详情
  {
    name: "goodsdetail",
    path: "/goodsdetail/:goodsId",
    component: (resolve) => require(['@/views/goodsdetail/index'], resolve),
    redirect: "/goodsdetail/:goodsId/detail",
    children: [
      {
        path: "detail",
        name: "detail",
        component: (resolve) => require(["views/goodsdetail/children/detail"], resolve),
        meta: {
          keepAlive: true,
          isUseCache: false
        }
      },
      {
        path: "comment",
        name: "comment",
        component: (resolve) => require(["views/goodsdetail/children/comment"], resolve),
        meta: {
          keepAlive: true,
          isUseCache: false
        }
      }]
  },
  // 客服聊天
  {
    name: "customerChat",
    path: "/customerChat",
    component: (resolve) => require(['@/views/customerChat/index'], resolve),
    // 需要登录才能进入的页面可以增加一个meta属性
    meta: {
      requireAuth: true,
      noTop: true
    }
  },
  //微信获取cod等待
  {
    name: "looding",
    path: "/looding",
    component: (resolve) => require(['@/views/orderconfirm/looding'], resolve)
  },
  // 支付确认结果
  {
    name: "payResult",
    path: "/payResult",
    component: (resolve) => require(['@/views/payResult/index'], resolve)
  },
  // 支付确认结果
  {
    name: "aliPay",
    path: "/aliPay",
    component: (resolve) => require(['@/views/orderconfirm/aliPay'], resolve)
  },
  //获取openID
  {
    name: "getOpenId",
    path: "/getOpenId",
    component: (resolve) => require(['@/views/orderconfirm/getOpenId'], resolve)
  },
  //登录获取openID
  {
    name: "getOpenIdIndex",
    path: "/getOpenIdIndex",
    component: (resolve) => require(['@/views/login/getOpenId'], resolve)
  },
   //第三方登录获取openID
   {
    name: "getOpenIdFrom",
    path: "/getOpenIdFrom",
    component: (resolve) => require(['@/views/Index/getOpenId'], resolve)
  },
  
  // 支付确认结果
  {
    name: "invoice",
    path: "/invoice",
    component: (resolve) => require(['@/views/orderconfirm/invoice'], resolve)
  },
]

const router = new Router({
  // 保证每个页面一进入就在页面顶部--可配置
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // 如果有后退、前进功能，就保留浏览器原始位置
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return { x: 0, y: to.meta.savedPosition || 0 };
    }
  },
  routes: routes
})


// 路由守卫 全部配置  在每个路由页面进入的时候，判断是否登录--权限配置
router.beforeEach((to, from, next) => {
  const { isHuawei } = to.query;
  if (isHuawei && isHuawei.length > 0) {
    localStorage.setItem("isHuawei", isHuawei);
  }

  if (to.meta.requireAuth) {
    if (localStorage.getItem("token")) {
      next();
    } else {
      Toast({
        message: "请登录，解锁更多特权，尊享独家优惠...",
        duration: 800,
        onClose: function () {
          next({
            path: "/login",
            query: { redirect: to.fullPath }
          });
        }
        
      });
    }
  } else {
    next();
  }
});

export default router;
