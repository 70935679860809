<template>
  <div class="loadingInfinity">
    <!-- <img src="@/assets/images/index.rotating-balls-spinner.svg" alt /> -->
    <img :src="$globalConfig.loadingInfinityImg" alt />
    正在加载中...
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>
<style lang="less" scoped>
.loadingInfinity {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.28rem /* 14/50 */;

  > img {
    margin-right: 10px;
    width: 0.32rem /* 16/50 */;
  }
}
</style>
