import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  // return Cookies.get(TokenKey)
  return localStorage.token
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  // return Cookies.remove(TokenKey)
  return localStorage.removeItem('token')
}

export function getOpenId() {
  return sessionStorage.getItem('openId')
}

export function setOpenId(openId) {
  return sessionStorage.setItem('openId', openId)
}

export function removeOpenId() {
  return sessionStorage.removeItem('openId')
}

