import request from '@/utils/request'
import QS from 'qs'
export default {
    //获取用户代金券列表
    getUserCouponsAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'coupon',
                _mt: 'getUserCoupons',
                ...query
            }) 
        });
    },
    //获取用户默认地址
    getDefAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'address',
                _mt: 'getDefAddress',
                ...query
            }) 
        });
    },
    //得到订单的运费
    getFreightPriceAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'freight',
                _mt: 'getFreightPrice',
                ...query
            }) 
        });
    },
    //提交订单
    takeOrderAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'order',
                _mt: 'takeOrder',
                ...query
            }) 
        });
    },

    //0元购
    noPrepay(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'order',
                _mt: 'noPrepay',
                ...query
            }) 
        });
    },

    //线下支付
    offlinePrepay(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'order',
                _mt: 'offlinePrepay',
                ...query
            }) 
        });
    },


    //支付宝H5支付
    zfbPayAddress(query) {
        return request({
            url: "/app_lsmall_com/api/pay_aliPay/pay/toPay",
            method: "post",
            headers: {
                // "Content-Type": "application/json;charset=utf-8",
                'Content-Type': 'application/x-www-form-urlencoded'

            },
            data: QS.stringify(
             
                // subject: query.subject,
                // out_trade_no: query.out_trade_no,
                // total_amount: query.total_amount,
                // payType: 6,
                // returnUrl: query.returnUrl,
                
                {...query}
            )  
            // url: "/mapi",
            // method: "post",
            // headers: {
            //     'Content-Type': 'application/x-www-form-urlencoded'
            // },
            // data: QS.stringify({
            //     _gp: 'alipay',
            //     _mt: 'aliPayToOrder',
            //     payDto: JSON.stringify({
            //         ...query
            //     })
            // }) 
        });
    },
    //微信支付
    wxPrepayAddress(query) {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let accessToken = userInfo ? userInfo.accessToken : ''
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'ACCESSTOKEN': accessToken
            },
            data: QS.stringify({
                _gp: 'order',
                _mt: 'wxPrepay',
                ...query
            }) 
        });
    },


    getSign(query) {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let accessToken = userInfo ? userInfo.accessToken : ''
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'ACCESSTOKEN': accessToken
            },
            data: QS.stringify({
                _gp: 'user',
                _mt: 'getSign',
                ...query
            }) 
        });
    },

    // 获取我的默认发票管理界面
    getInvoiceUserDefult(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: QS.stringify({
                _gp: 'invoiceUser',
                _mt: 'getInvoiceUserDefult',
                ...query
            }) 
        });
    },
     // 提交缓存发票信息
     inertCache(query){
        return request({
            url:"/mapi",
            method:"post",
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data:QS.stringify({
                _gp: "invoiceUser",
                _mt: "inertCache",
                ...query
            })
        })
    },
    //获取缓存发票信息
    getCache(query){
        return request({
            url:"/mapi",
            method:"post",
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data:QS.stringify({
                _gp: "invoiceUser",
                _mt: "getCache",
                ...query
            })
        })
    },

    verifyUser() {
        let accessToken = localStorage.token
        return request({
            url: "/api/user/verifyUser",
            method: "get",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'token': accessToken
            },
           
        });
    },
    //获取oppoID
  
    getOpenId(code){
        return request({
            url:"/wx/getOpenId?code=" + code,
            method:"get",
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
           
        })
    },

  }