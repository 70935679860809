import request from '@/utils/request'
import QS from 'qs'
import home from './home'
import classfication from './classfication'
import login from './login'
import my from './my'
import shopcar from './shopcar'
import order from './order'
import alone from './alone'
import orderZfb from './orderZfb'
import distributor from './distributor'
import stash from './stash'
export default {
    home: home,
    classfication: classfication,
    login: login,
    my: my,
    shopcar,
    order,
    alone,
    orderZfb,
    distributor,
    stash
}
export const server = {
    /**
     * 年货街三级界面
     */
     newYearThirdAddress(param) {
        return request({
            url: "/core/api/sales/op/mall/Goods_newYearThird",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify(param)
        });
    },
    /**
     * 主题精选二级界面
     */
     topicSelectSecondAddress(param) {
        return request({
            url: "/core/api/sales/op/mall/Goods_goodsHomeVOSelectedSecond",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify(param)
        });
    },
    /**
     * 年货街三级界面
     */
     importGoodsSecondAddress(param) {
        return request({
            url: "/core/api/sales/op/mall/Goods_secondPageWithCategory",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify(param)
        });
    },
    /**
     * 根据type获取商品
     */
     goodsByTypeAddress(param) {
        return request({
            url: "/core/api/sales/op/mall/Goods_byType",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify(param)
        });
    },
    tourismAddress(param) {
        return request({
            url: "/core/api/sales/op/mall/Goods_byType",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify(param)
        });
    },
     /**
     * 爆款推荐详情
     */
      loadGoodsHomeVOBoomAddress(param) {
        return request({
            url: "/core/api/sales/op/mall/Goods_loadGoodsHomeVOBoom",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify(param)
        });
    },
    /**
     * 商品详情
     */
     goodsDetailAddress(query) {
        return request({
            url: '/mapi',
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: {
                _gp: 'goods',
                _mt: 'getGoods',
                ...query
            }
        });
    }
   
}