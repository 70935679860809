import request from '@/utils/request'
import QS from 'qs'


export default {
    /**
     * 一级分类tab
     */
     oneClassListAddress(query) {
        return request({
            url: '/mapi',
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: {
                _gp: 'category',
                _mt: 'categoryList',
                ...query
            }
        });
    },
    /**
     * 二级分类tab
     */
     getFoodsSelected(query) {
        return request({
            url: '/mapi',
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: {
                _gp: 'category',
                _mt: 'getFoodsSelected',
                ...query
            }
        });
    },


    //获取食品的一级分类
}
