<template>
  <div id="goTop">
    <div class="goTop" v-show="goTopShow" @click="goTop">
      <!-- <i class="iconfont goTopIcon icon-fanhuidingbu-"></i> -->
      <div>回到顶部</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'goTop',
  data() {
    return {
      scrollTop: '',
      goTopShow: false
    };
  },
  watch: {
    scrollTop(val) {
      if (this.scrollTop > 800) {
        this.goTopShow = true;
      } else {
        this.goTopShow = false;
      }
    }
  },
  methods: {
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.scrollTop > 800) {
        this.goTopShow = true;
      }
    },
    goTop() {
      window.pageYOffset = document.documentElement.scrollTop = document.body.scrollTop = 0;
      // let timer = null,
      //   _that = this;
      // cancelAnimationFrame(timer);
      // timer = requestAnimationFrame(function fn() {
      //   if (_that.scrollTop > 0) {
      //     _that.scrollTop -= 50;
      //     document.body.scrollTop = document.documentElement.scrollTop = 0;
      //     // _that.scrollTop;
      //     timer = requestAnimationFrame(fn);
      //   } else {
      //     cancelAnimationFrame(timer);
      //     _that.goTopShow = false;
      //   }
      // });
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>
 
<style scoped lang='less'>
@height: .56rem /* 28/50 */;
.goTop {
  position: fixed;
  right: .32rem /* 16/50 */;
  bottom: 3.52rem /* 176/50 */;
  width: @height;
  height: @height;
  border-radius: 50%;
  background: #fff;
  padding: 10px;
  text-align: center;
  z-index: 999;
  cursor: pointer;
  -webkit-box-shadow: 1px 3px 8px #b6b2b2;
  box-shadow: 1px 3px 8px #b6b2b2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: .2rem /* 10/50 */;
}
.goTopIcon {
  font-size: .44rem /* 22/50 */;
  font-weight: bold;
  color: #333;
}
</style>