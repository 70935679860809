import { render, staticRenderFns } from "./loadingrem.vue?vue&type=template&id=7263cf83&scoped=true&"
import script from "./loadingrem.vue?vue&type=script&lang=js&"
export * from "./loadingrem.vue?vue&type=script&lang=js&"
import style0 from "./loadingrem.vue?vue&type=style&index=0&id=7263cf83&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7263cf83",
  null
  
)

export default component.exports