import request from '@/utils/request'
import QS from 'qs'


export default {
    /**
     * 一级分类tab
     * @param {*} param
     */
    //  yanCodeAddress(param) {
    //     return request({
    //         url: "/core/api/customer/getMobileCode",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },

    //获取验证码

    yanCodeAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'user',
                _mt: 'sendVerifyCode',
                ...query
            }) 
        });
    },

    //修改手机号
    
    editPhone(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'user',
                _mt: 'editPhone',
                ...query
            }) 
        });
    },



    /**
     * 二级分类tab
     * @param {*} param
     */
    //  loginAddress(param) {
    //     return request({
    //         url: "/core/system/Login_customerRegisterAndLoginDhmall",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },

    loginAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'user',
                _mt: 'login',
                ...query
            }) 
        });
    },


    //退出登录
    loginOutAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'user',
                _mt: 'logout',
                ...query
            }) 
        });
    },


    //微信登录

    // wxgo() {
    //     return request({
    //         url: "/mapi/wx/openIndex",
    //         method: "get",
           
    //     });
    // },
    wxgo(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'user',
                _mt: 'wxLogin',
                ...query
            }) 
        });
    },
    //微信绑定
    wxRegister(query){
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'user',
                _mt: 'wxRegister',
                ...query
            }) 
        });
    },

    //苹果登录

    appleLogin(query){
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'user',
                _mt: 'appleLogin',
                ...query
            }) 
        });
    },

    //是否清除缓存
    getH5Version(query){
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'config',
                _mt: 'getH5Version',
                version :query
            }) 
        });
    },

    //对比版本号
    getAppVersion(query){
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'config',
                _mt: 'getAppVersion',
                ...query
            }) 
        });
    },
    //第三方登录
    InternalLogin(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'user',
                _mt: 'InternalLogin',
                ...query
            }) 
        });
    },
    // 获取动态logo或微信appId
    getConfigLogoUrl(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'config',
                _mt: 'getConfigV',
                ...query
            }) 
        });
    },
}

