import request from '@/utils/request'
import QS from 'qs'


export default {
    // /**
    //  * 所有分类
    //  * @param {*} param
    //  */
    //  getGoodsCategoryAll() {
    //     return request({
    //         url: '/core/api/sales/op/mall/GoodsCategory_getGoodsCategoryAll',
    //         method: 'post'
    //     })
    // },
    // /**
    //  * 首页轮播图
    //  * @param {*} param
    //  */
    // getBanner(param) {
    //     return request({
    //         url: "/core/api/sales/Article_banner",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 猜你喜欢
    //  * @param {*} param
    //  */
    //  guessYouLikeAddress(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/FootPrint_guessYouLike",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 图片分类
    //  * @param {*} param
    //  */
    //  getIndividualImgCategroy(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/GoodsCategory_getGoodsCategoryIndividual",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 云南特色馆
    //  * @param {*} param
    //  */
    //  goodsCategory(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/GoodsCategory_getByParentType",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 爆款推荐
    //  * @param {*} param
    //  */
    //  explosionAddress(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/Goods_goodsHomeBoom",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 首页商品列表+分类商品
    //  * @param {*} param
    //  */
    //  goodsListAddress(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/Goods_listGoodsHomeVO",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 主题精选
    //  * @param {*} param
    //  */
    //  topicGoodsAddress(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/Goods_goodsHomeVOSelected",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },

    // *********************************************************************************
    /**
     * 所有分类
     */
    getGoodsCategoryAll(query) {
        return request({
            // url: '/core/api/sales/op/mall/GoodsCategory_getGoodsCategoryAll',
            url: '/mapi',
            method: 'post',
            params: {
                _gp: 'category',
                _mt: 'categoryList',
                ...query
            }
        })
    },
    /**
     * 首页轮播图
     */
    getBanner(query) {
        return request({
            url: '/mapi',
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'advertisement',
                _mt: 'getActiveAd',
                ...query
            }) 
        });
    },
    /**
     * 猜你喜欢
     */
    guessYouLikeAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'goods',
                _mt: 'guessYouLike',
                ...query
            }) 
        });
    },
    /**
     * 图片分类
     */
    getIndividualImgCategroy(query) {
        return request({
            url: '/mapi',
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: {
                _gp: 'category',
                _mt: 'categoryList',
                ...query
            }
        });
    },
    /**
     * 云南特色馆
     */
    goodsCategory(query) {
        return request({
            url: '/mapi',
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: {
                _gp: 'advertisement',
                _mt: 'getActiveAd',
                ...query
            }
        });
    },
    /**
     * 爆款推荐
     */
    explosionAddress(query) {
        return request({
            url: '/mapi',
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: {
                _gp: 'category',
                _mt: 'getGoodsHotSelected',
                ...query
            }
        });
    },
    /**
     * 主题精选
     */
    topicGoodsAddress(query) {
        return request({
            url: '/mapi',
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: {
                _gp: 'category',
                _mt: 'getGoodsThemeSelected',
                ...query
            }
        });
    },
    /**
     * 首页商品列表+分类商品
     */
     goodsListAddress(query) {
        return request({
            url: '/mapi',
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: {
                _gp: 'goods',
                _mt: 'getGoodsPage',
                ...query
            }
        });
    },
    /**
     * 首页疯狂特卖列表详情
     */
     getGoodsPageAddress(query) {
        return request({
            url: '/mapi',
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: {
                _gp: 'goods',
                _mt: 'getGoodsPage',
                ...query
            }
        });
    },
    /**
     * 查询搜索历史
     */
     completeSearchAddress(query) {
        return request({
            url: '/mapi',
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: {
                _gp: 'goods',
                _mt: 'getTitleLike',
                ...query
            }
        });
    },
    /**
     * 查询搜索历史
     */
     searchGoodsListAddress(query) {
        return request({
            url: '/mapi',
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: {
                _gp: 'goods',
                _mt: 'getGoodsPage',
                ...query
            }
        });
    },

        /**
     * 收藏商品
     */
         addCollect(query) {
            return request({
                url: '/mapi',
                method: "post",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                params: {
                    _gp: 'collect',
                    _mt: 'addCollect',
                    ...query
                }
            });
        },

        //删除收藏
        deleteCollect(query) {
            return request({
                url: '/mapi',
                method: "post",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                params: {
                    _gp: 'collect',
                    _mt: 'deleteCollect',
                    ...query
                }
            });
        },

        //获取该商品所有评论
        getSpuAllAppraise(query) {
            return request({
                url: '/mapi',
                method: "post",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                params: {
                    _gp: 'appraise',
                    _mt: 'getSpuAllAppraise',
                    ...query
                }
            });
        },

        //获取食堂的图片
        getFoodCategory(query) {
            return request({
                url: '/mapi',
                method: "post",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                params: {
                    _gp: 'user',
                    _mt: 'getFoodCategory',
                    ...query
                }
            });
        },



        // 获取服务器code
        getCode(){
            return request({
                url: '/wx/openIndex',
                method: "get",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                params: {}
            });
        },

         //获取小店名称
         getStoreInfo(id) {
            return request({
                url: '/mapi',
                method: "post",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                params: {
                    _gp: 'store',
                    _mt: 'getStoreInfo',
                    id
                }
            });
        },


    // **************************************************************************************
    // /**
    //  * 主题精选轮播图
    //  */
    // topicBannerAddress(param) {
    //     return request({
    //         url: "/core/api/sales/Article_bannerSelected",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 查询搜索历史
    //  */
    //  historyAddress(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/SearchHistory_getCustomerSearchHistoryPOs",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 删除历史
    //  */
    //  delhistoryAddress(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/SearchHistory_removeAll",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 新增搜索历史
    //  */
    //  addHistoryAddress(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/SearchHistory_addCustomerSearchHistory",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 查询搜索历史
    //  */
    //  searchGoodsListAddress(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/Goods_searchGoodsByName",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 查询搜索历史
    //  */
    //  completeSearchAddress(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/Goods_complementGoodsName",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 文章详情
    //  */
    //  articleDetailAddress(param) {
    //     return request({
    //         url: "/core/api/sales/Article_detail",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 云南特色馆二级
    //  */
    //  YunNanSpecialAddress(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/Goods_YunNanSpecial",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 添加足迹
    //  */
    //  addFootPrintAddress(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/FootPrint_addCustomerFootPrint",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 预下单
    //  */
    //  preOrderAddress(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/Order_preOrder",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 收藏商品
    //  */
    //  newcollectAddress(param) {
    //     return request({
    //         url: "/core/api/sales/Collection_newOrDelete",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 加入购物车
    //  */
    //  addShopcarAddress(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/Order_addToCart",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 后台记录分享
    //  */
    //  shareGoodsHomeAddress(param) {
    //     return request({
    //         url: "/core/api/system/Share_shareGoodsHome",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 获取消息列表
    //  */
    //  chatListAddress(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/Message_listMessage",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    // /**
    //  * 新发消息
    //  */
    //  newChatAddress(param) {
    //     return request({
    //         url: "/core/api/sales/op/mall/Message_newCustomerMessage",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify(param)
    //     });
    // },
    
}
