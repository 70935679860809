import Vue from "vue";
import router from "./router";
import {
  Toast
} from "vant";
var that = new Vue({
  router
});

let accessToken = localStorage.getItem("token");
let loginToken = localStorage.getItem("token");
let customerLevel = localStorage.getItem("customerLevel");
// 收藏商品
function tollgleCollectsell(index, id) {
  var flag = this.goodslist[index].collectStatus;
  if (accessToken) {
    if (flag == "1") { // 如果为真
      this.$server.newcollectAddress({
        accessToken,
        collectType: "3", // 收藏类型 1产品,2文章,3商品
        bizId: id // 收藏对象Id
      }).then(res => {
        if (res.returnValue) {
          this.goodslist[index].collectStatus = false;
        }
      });
    } else {
      this.$server.newcollectAddress({
        accessToken,
        collectType: "3", // 收藏类型 1产品,2文章,3商品
        bizId: id // 收藏对象Id
      }).then(res => {
        if (res.returnValue) {
          this.goodslist[index].collectStatus = true;
        }
      });
    }
  } else {
    Toast('收藏需要登录，可以前往"我的"进行登录');
  }
};
// 进入商品详情
function goGoodsDetail(goodsId, status, companyId) {
  this.$router.push(`/goodsdetail/${goodsId}?status=${status}&companyId=${companyId}`);
}
// 进入文章详情
function goArticleDetail(id) {
  this.$router.push(`/articledetail/${id}`);
}
// 判断设备环境
function deviceEnvir() {
  let u = navigator.userAgent;
  let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // 判断是否是 android终端
  let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否是 ios终端
  let isWeiChat = u.match(/MicroMessenger/i) == "micromessenger"; // 判断是否是微信环境
  if (isAndroid === true) {
    // 安卓
    return "ANDROID";
  } else if (isIOS === true) {
    // IOS
    return "IOS";
  } else if (isWeiChat === true) {
    //
    return "WEICHAT";
  } else {
    return "PC";
  }
};
// 处理emoji表情的输入
function utf16toEntities(str) { // 检测utf16emoji表情 转换为实体字符以供后台存储
  var patt = /[\ud800-\udbff][\udc00-\udfff]/g;
  str = str.replace(patt, function (char) {
    var H, L, code;
    if (char.length === 2) { // 辅助平面字符（我们需要做处理的一类）
      H = char.charCodeAt(0); // 取出高位
      L = char.charCodeAt(1); // 取出低位
      code = (H - 0xD800) * 0x400 + 0x10000 + L - 0xDC00; // 转换算法
      return "&#" + code + ";";
    } else {
      return char;
    }
  });
  return str;
};
function currentTime() {
  var now = new Date();
  var year = now.getFullYear(); // 年
  var month = now.getMonth() + 1; // 月
  var day = now.getDate(); // 日
  var hh = now.getHours();// 时
  var mm = now.getMinutes();// 分
  var clock = year + "-";
  if (month < 10) {
    clock += "0"; 
  }
  clock += month + "-";
  if (day < 10) {
    clock += "0"; 
  }
  clock += day + " ";
  if (hh < 10) {
    clock += "0"; 
  }
  clock += hh + ":";
  if (mm < 10) clock += "0";
  clock += mm;
  return (clock);
};
export default {
  tollgleCollectsell,
  accessToken,
  customerLevel,
  goGoodsDetail,
  goArticleDetail,
  deviceEnvir,
  utf16toEntities,
  currentTime
};
