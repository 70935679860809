<template>
  <div class="loading dark_bg">
    <p>
      <!-- <img src="../../assets/images/logo.png" alt class="logo" /> -->
      <!-- <span class="loading-icon dark_loading"></span> -->
      <img :src="valueWorth" alt class="logo" />
      <span class="logotxt">加载中......</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      valueWorth: "",
    }
  },
  created() {
    // let logoLoadingLogoUrl = localStorage.getItem('logoLoadingLogoUrl')
    // if(logoLoadingLogoUrl) {
    //   this.valueWorth = logoLoadingLogoUrl
    // } else {
    //   this.getLoadingLogoUrl();
    // }
    this.getLoadingLogoUrl();
  },
  methods:{
    getLoadingLogoUrl() {
      this.$api.login
        .getConfigLogoUrl({
          k: "readLog",
        })
        .then((res) => {
          // 成功
          if (res.errno === 200) {
            this.valueWorth = res.data.valueWorth;
            localStorage.setItem('logoLoadingLogoUrl', this.valueWorth)
            // console.log(this.valueWorth);
          } else {
            this.$toast(res.errmsg);
          }
        });
    },
  }
};
</script>

<style lang='less' scoped>
@keyframes myfirst {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -50px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes heartbeat {
  0% {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  25% {
    transform: translate(0px, -75px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
.loading-icon {
  display: block;
  width: 100%;
  height: 2.4rem /* 120/50 */;
  background: url("../../assets/images/logo.png") no-repeat;
  background-size: 100% 100%;
}
.loading {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  p {
    text-align: center;
    width: 30%;
    .logotxt {
      font-size: 0.26rem /* 13/50 */;
      color: #a9a6a6;
    }
  }
  img {
    width: 100%;
    display: block;
  }
  .loading-icon {
    animation: heartbeat 1.3s infinite;
    -webkit-animation: heartbeat 1.3s infinite;
  }
}
</style>
