import { setOpenId } from '@/utils/auth'
const user = {
  state: {
    version: '3.1.4',
    openId: ''
  },

  mutations: {
    SET_VERSION: (state, version) => {
      state.version = version
    },
    SET_OPENID: (state, openId) => {
      state.openId = openId
    }
  },

  actions: {
    // 微信环境下获取openId
    getOpenId({ commit }) {
      return new Promise((resolve, reject) => {
        this.$api.home.getGoodsCategoryAll().then(res => {
          //console.log(res,openid);
          setOpenId(res.openId)
          commit('SET_OPENID', res.openId)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default user
