<template>
  <div class="navbar">
    <!-- <router-link to="/index" class="navbar-item" active-class="active">
      <span class="iconfont icon icon-shouye1"></span>
      <span class="navbar-name">首页</span>
    </router-link>
    <router-link to="/classFication" class="navbar-item" active-class="active">
      <span class="iconfont icon icon-fenlei4"></span>
      <span class="navbar-name">分类</span>
    </router-link>
    <router-link to="/shoppingcar" class="navbar-item" active-class="active">
      <span class="iconfont icon icon-gouwuche3"></span>
      <span class="navbar-name">购物车</span>
    </router-link>
    <router-link to="/my" class="navbar-item" active-class="active">
      <span class="iconfont icon icon-wode-"></span>
      <span class="navbar-name">我的</span>
    </router-link> -->
    <router-link
      :to="item.path"
      class="navbar-item"
      active-class="active"
      v-for="(item, index) in list"
      :key="index"
    >
      <span class="iconfont icon" :class="item.icon"></span>
      <span class="navbar-name">{{ item.name }}</span>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // list: [],
      // list: this.$globalConfig.tabBarList,
      list: [
        {
          path: "/index",
          name: "首页",
          icon: 'icon-shouye1',
        },
         {
          path: "/classFication",
          name: "分类",
          icon: 'icon-fenlei4',
        },
         {
          path: "/shoppingcar",
          name: "购物车",
          icon: 'icon-gouwuche3',
        },
        {
          path: "/my",
          name: "我的",
          icon: 'icon-wode-',
        }
      ],
    };
  },
  created() {
    this.getConfigThemetabBar();
    // let haveConfigThemetabBar = localStorage.getItem("configThemetabBar");
    // if (!haveConfigThemetabBar) {
    //   this.getConfigThemetabBar();
    // } else {
    //   // this.list = this.$globalConfig.tabBarList
    // }
  },
  methods: {
    getConfigThemetabBar() {
      this.$api.login
        .getConfigLogoUrl({
          k: "Theme",
        })
        .then((res) => {
          // 成功
          if (res.errno === 200) {
            // localStorage.setItem("configThemetabBar", res.data.valueWorth);
            // tabBar配置
            switch (res.data.valueWorth) {
              case "1":
                this.list = [
                  {
                    path: "/index",
                    name: "首页",
                    icon: "icon-shouye1",
                  },
                  {
                    path: "/classFication",
                    name: "分类",
                    icon: "icon-fenlei4",
                  },
                  {
                    path: "/shoppingcar",
                    name: "购物车",
                    icon: "icon-gouwuche3",
                  },
                  {
                    path: "/my",
                    name: "我的",
                    icon: "icon-wode-",
                  },
                ];
                break;
              case "2":
                this.list = [
                  {
                    path: "/index",
                    name: "首页",
                    icon: "icon-shouye",
                  },
                  {
                    path: "/classFication",
                    name: "分类",
                    icon: "icon-gongnengkaiguan",
                  },
                  {
                    path: "/shoppingcar",
                    name: "购物车",
                    icon: "icon-gouwuche",
                  },
                  {
                    path: "/my",
                    name: "我的",
                    icon: "icon-xingming",
                  },
                ];
                break;
              default:
                this.list = [
                  {
                    path: "/index",
                    name: "首页",
                    icon: "icon-shouye1",
                  },
                  {
                    path: "/classFication",
                    name: "分类",
                    icon: "icon-fenlei4",
                  },
                  {
                    path: "/shoppingcar",
                    name: "购物车",
                    icon: "icon-gouwuche3",
                  },
                  {
                    path: "/my",
                    name: "我的",
                    icon: "icon-wode-",
                  },
                ];
                break;
            }
          } else {
            this.$toast(res.errmsg);
          }
        });
    },
  },
  watch: {
  },
};
</script>
<style lang="less" scoped>
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  #tabbar-box {
    padding-bottom: 1rem;
  }
}
.navbar {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // padding: 0 0 0.1rem;
  padding: 0.1rem 0;
  box-shadow: 0px 0px 20px 0px @navbar_box_shadow;
  height: 1.08rem /* 54/50 */;
  .navbar-item {
    text-align: center;
    span {
      display: block;
      color: #999;
    }
    .icon {
      // font-size: 0.65rem;
      font-size: 0.6rem /* 30/50 */;
    }
    .navbar-name {
      font-size: 0.27rem;
      margin-top: -0.1rem;
      // margin-top: 0.5px;
    }
  }
}
.active {
  span {
    color: @tabBar_active_color !important;
  }
}
</style>