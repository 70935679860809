import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { methods } from '@/utils/index.js'
import { getOpenId } from '@/utils/auth'
NProgress.configure({ showSpinner: false })

router.beforeEach((to, from, next) => {
    // console.log(to,from)
    if (methods.deviceEnvir === "WEICHAT") {
        if (getOpenId()) {
            next()
        } else {
            NProgress.start()
            next()
            NProgress.done()
        }
    } else {
        next()
    }
})

