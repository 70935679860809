<template>
  <div class="switch">
    <!-- <van-switch v-model="checked" @change="onChange()" /> -->
    <input
      type="color"
      id="colorPicker"
      v-model="color"
      @change="changeColor(color)"
    />
    <!-- <div class="vcolorpicker-box">
      <colorPicker v-model="color" defaultColor="#eb5c24" @change="changeColor" class="vcolorpicker"></colorPicker>
    </div> -->

    <van-button
      id="div"
      @click="goClick()"
      :style="{ 'background-color': bgc }"
    ></van-button>
    <van-popup
      v-model="show"
      position="bottom"
      closeable
      close-icon="close"
      round
      class="popup"
    >
      <div class="colorTitle">颜色</div>
      <sketch-picker
        v-model="colors"
        @input="updateValue"
        v-if="show"
        :preset-colors="preSetColors"
      />
    </van-popup>
  </div>
</template>

<script>
import { Sketch } from "vue-color";
// let defaultProps = {
//   hex: '#194d33e6',
//   hsl: {
//     h: 150,
//     s: 0.5,
//     l: 0.2,
//     a: 0.9
//   },
//   hsv: {
//     h: 150,
//     s: 0.66,
//     v: 0.30,
//     a: 0.9
//   },
//   rgba: {
//     r: 159,
//     g: 96,
//     b: 43,
//     a: 0.9
//   },
//   a: 0.9
// }
export default {
  name: "",
  components: {
    "sketch-picker": Sketch,
  },
  props: {},
  data() {
    return {
      checked: localStorage.getItem("theme") == "dark" ? true : false,
      color: localStorage.getItem("themeUserDefined")
        ? localStorage.getItem("themeUserDefined")
        : "#eb5c24",
      show: false,
      colors: {
        hex: localStorage.getItem("themeUserDefined")
          ? localStorage.getItem("themeUserDefined")
          : "#eb5c24ff",
        // hex: "#194d33",
        // hex8: '#194D33A8',
        // hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
        // hsv: { h: 150, s: 0.66, v: 0.30, a: 1 },
        // rgba: { r: 25, g: 77, b: 51, a: 1 },
        // a: 1
      },
      preSetColors: [
        "#f00",
        "#00ff00",
        "#00ff0055",
        "rgb(201, 76, 76)",
        "rgba(0,0,255,1)",
        "hsl(89, 43%, 51%)",
        "hsla(89, 43%, 51%, 0.6)",
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    changeColor(color) {
      let element = document.querySelector("[data-theme]");
      element.style.setProperty("--theme", color);
      localStorage.setItem("themeUserDefined", color);
    },
    // 开关切换两套主题
    onChange() {
      console.log(this.checked);
      var element = document.querySelector("[data-theme]");
      if (this.checked) {
        element.setAttribute("data-theme", "dark");
        localStorage.setItem("theme", "dark");
      } else {
        element.setAttribute("data-theme", "default");
        localStorage.setItem("theme", "default");
      }
    },
    goClick() {
      console.log("触发l");
      this.show = true;
    },
    updateValue(value) {
      this.colors = value;
      console.log("修改颜色", value);
      let element = document.querySelector("[data-theme]");
      element.style.setProperty("--theme", value.hex8);
      localStorage.setItem("themeUserDefined", value.hex8);
    },
  },
  computed: {
    bgc() {
      console.log("修改颜色computed");
      return this.colors.hex8;
    },
  },
  watch: {},
};
</script>
<style lang="less" scoped>
// .vcolorpicker-box {
//   width: 100px;
//   height: 64px;
//   display: flex;
//   align-items: center;
// }
.switch {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#div {
  width: 1rem /* 50/50 */;
  height: 0.4rem /* 20/50 */;
  background: @ordinary_btn_color;
  box-shadow: 0px 0px 0.1rem 0px rgba(20, 20, 20, 0.05);
  border-radius: 0.04rem /* 2/50 */;
  margin-right: 0.1rem /* 5/50 */;
  margin-top: .3rem /* 15/50 */;
}
.popup {
  padding: 0.4rem /* 20/50 */ 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.colorTitle {
  font-size: 0.4rem /* 20/50 */;
}
.vc-sketch {
  width: 90vw;
  margin-top: 0.2rem /* 10/50 */;
  border: none !important;
  box-shadow: none;
}
/deep/ .van-button--default {
  border: 0.06rem /* 3/50 */ solid #ebedf0;
}
/deep/ .vc-saturation-circle {
  width: .3rem /* 15/50 */;
  height: .3rem /* 15/50 */;
}
#colorPicker {
  -webkit-appearance: none; /* 去除默认样式 */
  width: 1rem /* 50/50 */; /* 调整宽度以适应移动端 */
  height: 0.4rem /* 20/50 */; /* 调整高度以适应移动端 */
  border: none;

  // transition: transform 0.3s;

  // background-color: transparent;
  // float: right;
}
// #colorPicker:active {
//   // transform: scale(0.8);
//   // box-shadow: 0 10px #999;
//   // transform: translateY(5px);
//   // width: 1.2rem /* 60/50 */;
//   // height: .6rem /* 30/50 */;
//   box-shadow: 0px 0px 10px 0px rgba(20, 20, 20, 0.5);
//   opacity: 0.8;
// }

/* 自定义选择器 */
#colorPicker::-webkit-color-swatch-wrapper {
  padding: 0; /* 去除内边距 */
}

#colorPicker::-webkit-color-swatch {
  border: none; /* 去除边框 */
  border-radius: 0; /* 圆角形状 */
}
</style>
