<template >
  <div>
    <van-nav-bar
      class="head"
      :title="title"
      :left-arrow="arrowShow"
      @click-left="onClickLeft"
      fixed
    >
      <template #right v-if="neddRight">
        <slot></slot>
      </template>
    </van-nav-bar>
    <div class="pos"></div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "合同列表",
    },
    arrowShow: {
      type: Boolean,
      default: true,
    },
    redirect: {
      type: Boolean,
      default: false,
    },
    neddRight: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickLeft() {
      if (this.redirect) {
        // this.$router.push("/workSpace");
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style lang="less">
.head {
  height: 0.9rem;
  line-height: 0.9rem;
  z-index: 999;
  background-color: white;
  &::after{
    display: none;
  }
  .van-nav-bar__content {
    height: auto;
  }
  .van-icon {
    color: #a4a4a4 !important;
  }
   .van-nav-bar__left{
    padding: 0 .3rem;
  }
}
.pos {
  height: 0.9rem;
}

.van-nav-bar__title {
  color: #111111;
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #111111;
}
</style>