import request from '@/utils/request'
import QS from 'qs'

export default {
    // 分销员申请
    applyJoinDistribution(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: "user",
                _mt: "JoinDistribution",
                ...query
            })
        })
    },
    // 邀请分销员申请
    inviteJoinDistribution(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: "user",
                _mt: "ShareJoinDistribution",
                ...query
            })
        })
    },
    // 获取分销员信息
    getCommissionUserInfo(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: "userCommission",
                _mt: "getCommissionUserInfo",
                ...query
            })
        })
    },
    // 展示分销员分享出去的订单
    getCommissionOrder(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: "order",
                _mt: "getCommissionOrder",
                ...query
            })
        })
    },
}