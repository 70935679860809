import request from '@/utils/request'
import QS from 'qs'
export default {

    //获取用户购物车列表

    shopCarListAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'cart',
                _mt: 'getCartList',
                ...query
            }) 
        });
    },

    //购物车+1
    goodsNumCarAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'cart',
                _mt: 'addCartItem',
                ...query
            }) 
        });
    },
    //购物车-1

    goodsNumCarSub(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'cart',
                _mt: 'subCartItem',
                ...query
            }) 
        });
    },

    //购物车商品批量删除

    delMultileShopCarAddress(query) {
        return request({
            url: "/mapi",
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'cart',
                _mt: 'removeCartItemBatch',
                ...query
            }) 
        });
    },

    // 购买人数弹幕接口
    getOrderHistory(query){
        return request({
            url: "/mapi",
            method: "post",
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: QS.stringify({
                _gp: 'order',
                _mt: 'getOrderHistory',
                ...query
            }) 
        })
    },
     /**
     * 商品分享
     */
      createQrCode(query) {
        return request({
            url: '/mapi',
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: {
                _gp: 'shareGood',
                _mt: 'createQrCode',
                ...query
            }
        });
    },
     /**
     * 商品分享记录
     */
      insert(query) {
        return request({
            url: '/mapi',
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: {
                _gp: 'shareGood',
                _mt: 'insert',
                ...query
            }
        });
    },
       /**
     * 查询服务点
     */
        getServiceShop(query) {
            return request({
                url: '/mapi',
                method: "post",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                params: {
                    _gp: 'serviceShop',
                    _mt: 'getServiceShop',
                    ...query
                }
            });
        },


}