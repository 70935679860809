window.g = {
  // 域名
  wsbUrl: "ws://192.168.31.134:3333", // 客服
  // baseURL: "http://dhmall.keplerlab.cn", // 生产环境 
  // baseURL: "http://dhtest.keplerlab.cn", // 测试环境
  baseURL: "http://dhs.keplerlab.cn", // 模拟环境
  // 猜你喜欢
  guessYouLikeUrl: "/core/api/op/mall/guessYouLike",



  

  // 微信公众号回调地址

  // wxShareUrl: "http%3A%2F%2Fdhs.keplerlab.cn%2Fdhcircle%2F%23%2Fsharelogin", // 模拟环境
  wxShareUrl: "http%3A%2F%2Fdhtest.keplerlab.cn%2Fdhcircle%2F%23%2Fsharelogin", // 测试环境
  // wxShareUrl: "http%3A%2F%2Fdhcircle.keplerlab.cn%2Fdist%2F%23%2Fsharelogin", // 生产环境

  // wxUrl: "http%3A%2F%2Fbgf5uv.natappfree.cc%2F%23%2Fwxlogin", // 模拟环境
  
  // wxUrl: "http%3A%2F%2Fdhmall-app-v2.keplerlab.cn%2Fwxlogin", // 测试环境

  // wxUrl: "http://dhmall-app-v2.keplerlab.cn/#/wxlogin", // 测试环境
  // wxUrl: "http%3A%2F%2Fdhmall-app-v2.keplerlab.cn%2F%23%2Fwxlogin", // 测试环境


  wxUrl: "http://dhmall-service-v2.keplerlab.cn/wx/redirect", // 测试环境

  // wxUrl: "", // 测试环境



  // wxUrl: "http%253A%252F%252F192.168.31.104%3A8082%2F%252Fwxlogin", // 测试环境
  // wxUrl: "http%3A%2F%2Fdhcircle.keplerlab.cn%2Fdist%2F%23%2Fwxlogin", // 生产环境
  
  // 场外接口地址

  ticketUrl: 'http://47.107.71.240:8333',// 测试环境
  // ticketUrl: 'http://datain.keplerlab.cn/',// 生产环境

  // 微信公众号appid

  appid: "wxcff12fdb4319b38e" // 生产环境

};
