import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import preventReClick from "./directive/index"   // 自定义指令
import vant_import from "./utils/vant-import"   // vant组件
import waterfall from 'vue-waterfall2'    // 瀑布流插件
import navbar from "./components/navbar/indexOld"   // 导航栏
import navbarAlone from "./components/navbarAlone"   // 导航栏
import heador from "./components/header"   // 头部导航栏
import switchs from "./components/switch"   // 头部导航栏
import api from '@/api/api'   // api接口
import {
  server
} from '@/api/api'   // api接口
import { methods } from '@/utils/index.js'   // js通用方法
import { parseMoneyFormat, ifNull } from "@/utils/youngbook";
// 全局方法模块
import base from "./base.js";
import globalConfig from "./globalConfig.js";
import VueLazyload from 'vue-lazyload'
import preLoading from "./components/loading";
import preLoadingrem from "./components/loading/loadingrem";
import loadingInfinity from "@/components/common/loadingInfinity";//list滚动加载更多
import wxdl from '../static/js/config'
import * as filters from './filters' // global filters
// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})


import 'vant/lib/index.less';
import "@/assets/styles/reset.less";
import "@/assets/styles/variable.css";
import "./permission";  // 全局路由守卫注册
require('./assets/icon/iconfont.js')
require("./assets/icon/iconfont.css")

Vue.config.productionTip = false

// 全局方法挂载
Vue.prototype.$api = api
Vue.prototype.$server = server;
Vue.prototype.$methods = methods
Vue.prototype.parseMoneyFormat = parseMoneyFormat
Vue.prototype.ifNull = ifNull
Vue.prototype.Base = base;
Vue.prototype.$globalConfig = globalConfig;

// const globalData = {}
// Vue.prototype.$api = { globalData }
import vcolorpicker from 'vcolorpicker'

Vue.use(vcolorpicker)
Vue.use(waterfall)
Vue.use(preventReClick)
Vue.use(vant_import)
Vue.use(VueLazyload)

// 全局组件挂载
// import Vconsole from 'vconsole';
// let vConsole = new Vconsole();
// export default vConsole

Vue.component("NavBar", navbar);
Vue.component("NavBarAlone", navbarAlone);
Vue.component("heador", heador);
Vue.component("switchs", switchs);
Vue.component("preLoading", preLoading);
Vue.component("preLoadingrem", preLoadingrem);
Vue.component("loadingInfinity", loadingInfinity);
new Vue({
  router,
  store,
  data: {
    eventHub: new Vue() // 使用集中的时间处理器，一劳永逸的在任何组件调用事件发射，接受的方法,中央处理
  },
  render: h => h(App)
}).$mount('#app')
