<template>
  <div class="navbar">


    <van-tabbar class="navbar" v-model="active" @change="onChange">
      <van-tabbar-item :to="item.path" v-for="(item, index) in list" :key="index">
        <div class="item">
          <div class="item-box">
            <img
              :src="item.activeImg"
              alt=""
              v-if="activeFlag == item.activeFlag"
            />
            <img :src="item.img" alt="" v-else />
          </div>
          <div class="tabbar-name-active" v-if="activeFlag == item.activeFlag">{{item.name}}</div>
          <div class="tabbar-name" v-else>{{item.name}}</div>
        </div>
      </van-tabbar-item>
      
    </van-tabbar>


  </div>
</template>
<script>
export default {

  data(){
    return{
      active: 0,
      list: [
        {
          path: "/indexAlone",
          img: require("@/assets/images/home.png"),
          activeImg: require("@/assets/images/home-active.png"),
          name: "首页",
          activeFlag: 0,
        },
         {
          path: "/classFication",
          img: require("@/assets/images/classify.png"),
          activeImg: require("@/assets/images/classify-active.png"),
          name: "分类",
          activeFlag: 1,
        },
         {
          path: "/shoppingcar",
          img: require("@/assets/images/shopCar.png"),
          activeImg: require("@/assets/images/shopCar-active.png"),
          name: "购物车",
          activeFlag: 2,
        },
        {
          path: "/my",
          img: require("@/assets/images/my.png"),
          activeImg: require("@/assets/images/my-active.png"),
          name: "我的",
          activeFlag: 3,
        }
       
      ],
    }
  },
   props: {
    activeFlag: 0,
  },
  computed: {
    actives() {
      return this.str.split("").reverse().join("")
    },
  },
  methods: {
    onChange(index) {
    //   this.$toast({ message: index });
    },
  },

 
 
  
};
</script>


<style lang="less" scoped>
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  #tabbar-box {
    padding-bottom: 1rem;
  }
}
.navbar {
  position: fixed;
  bottom: 0;
  z-index: 99;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.1rem 0 0.1rem;
  // box-shadow: 0px 0px 20px 0px rgba(200, 57, 57, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(20, 20, 20, 0.1);
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .item-box {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  .tabbar-name {
    font-size: 10px;
    font-family: PingFang SC;
    font-weight: 400;
    margin-top: 4px;
    color: #7e7e7e;
  }
  .tabbar-name-active {
    font-size: 10px;
    font-family: PingFang SC;
    margin-top: 4px;
    font-weight: bold;
    color: #333333;
  }

}
// .active {
//   span {
//     color: #c83939 !important;
//   }
// }
/deep/ .van-tabbar-item__text {
  height: 44px;
}
</style>