<template>
  <div id="app">
    <!-- 是否使用缓存 -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- 返回顶部 -->
    <go-top></go-top>
    <go-dehe></go-dehe>

  </div>
</template>

<script>
import goTop from "@/components/public/goTop";
import goDehe from "./components/public/goDehe";

// 暴露组件
export default {
  name: "App",
  data() {
    return {

    };
  },
  components: {
    goTop,
    goDehe,

  },
  created() {
    // this.$globalConfig.setAttributeDataTheme ('dark')
    // console.log('全局配置', this.$globalConfig);
    this.getConfig()
    // if(!localStorage.getItem('appid')) {
    //   this.getConfigAppId()
    // }
    this.getConfigAppId()
  },
  methods: {
    getConfig() {
      document.title = this.$globalConfig.logoTitle
    },
    getConfigAppId() {
      this.$api.login
          .getConfigLogoUrl({
            k: 'APPID',
          })
          .then((res) => {
            // 成功
            if (res.errno === 200) {
              localStorage.setItem('appid', res.data.valueWorth)
            } else {
              this.$toast(res.errmsg);
            }
          });
    },
    
  }
};
</script>

<style lang="less">
</style>
