<template>
  <div class="go-dehe" v-if="goDeheShow" @click="onClickLeft"></div>
  <div class="go-salesman" v-else-if="goSalesman" @click="salesman"></div>
</template>
<script>
export default {
  data() {
    return {
      goDeheShow: localStorage.getItem("isDehe") || false,
      goSalesman: localStorage.getItem("isSalesman") || false
    };
  },
  methods: {
    onClickLeft() {
      const token = localStorage.getItem("token") || "";
      const customerId = localStorage.getItem("customerId" || "");
      window.location.href = `http://dhcircle.keplerlab.cn/dist/#/home?pagenum=1&token=${token}&customerId=${customerId}`;
      localStorage.removeItem("isDehe");
    },
    salesman() {
      window.location.href = "http://dhfc.keplerlab.cn/dist/#/home";
      localStorage.removeItem("isSalesman");
    }
  }
};
</script>
 
<style scoped lang='less'>
@height: .5rem;
.go-dehe,
.go-salesman {
  position: fixed;
  right: .3rem;
  bottom: 2.2rem;
  width: @height;
  height: @height;
  background: url(../../../static/images/dehelogo.png) no-repeat;
  border-radius: 50%;
  background-size: 100% 100%;
  padding: 10px;
  z-index: 999;
  -webkit-box-shadow: 1px 3px 5px #efd1d1;
  box-shadow: 1px 3px 5px #efd1d1;
}
.go-salesman {
  background: url(../../../static/images/salesman.png) no-repeat;
  background-size: 100% 100%;
}
</style>