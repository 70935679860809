import request from '@/utils/request'
import QS from 'qs'
export default {

//获取店铺信息
getStoreInfo(query) {
    return request({
        url: '/mapi',
        method: "post",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: QS.stringify({
            _gp: 'store',
            _mt: 'getStoreInfo',
            ...query
        }) 
    });
},
}