import request from '@/utils/request'
import QS from 'qs'
//获取主题颜色接口
function getConfigLogoUrl(query) {
  return request({
      url: "/mapi",
      method: "post",
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: QS.stringify({
          _gp: 'config',
          _mt: 'getConfigV',
          ...query
      }) 
  });
}

// 获取主题颜色
window.onload = function setAttributeDataTheme () {
  let element = document.querySelector('[data-theme]')
  
  getConfigLogoUrl({
    k: "Color",
  })
  .then((res) => {
    console.log('获取主题颜色', res);
    if (res.errno === 200) {
      let colors = res.data.valueWorth
      if(colors) {
        element.style.setProperty("--theme", colors);
      }
    }
  })
};

// // 用户选择颜色换主题
// window.onload = function setAttributeDataTheme () {
//   let element = document.querySelector('[data-theme]')
//   let colors = localStorage.getItem("themeUserDefined")
//   if(colors) {
//     element.style.setProperty("--theme", colors);
//   }
// };

// let redirectuUrl = 'http://service.mall.hongyuantrading.com'
// let redirectuUrl = 'http://139.9.157.96:8189'
let redirectuUrl = 'http://service.mall.yunchu.com.cn'//云楚生产

let logoTitle = '云楚新能源'

let finishedText = '没有更多了'

// loading加载svg
let loadingInfinityImg = require("@/assets/images/index.rotating-balls-spinner.svg")

// 我的订单配置
let orderList = [
  {
    name: "待付款",
    status: "1",
    names: "待付款",
    img: require("@/assets/images/待付款.png"),
    icon: 'icon-zhanghuguanli1',
  },
  {
    name: "待发货",
    status: "2",
    names: "待出库",
    img: require("@/assets/images/待发货.png"),
    icon: 'icon-daifahuo',
  },
  {
    name: "待收货",
    status: "3",
    names: "待收货",
    img: require("@/assets/images/待收货.png"),
    icon: 'icon-daishouhuo',
  },
  {
    name: "待评价",
    status: "4",
    names: "待评价",
    img: require("@/assets/images/待评价.png"),
    icon: 'icon-daipingjia',
  },
  {
    name: "退换/售后",
    status: "5",
    names: "退换/售后",
    img: require("@/assets/images/售后.png"),
    icon: 'icon-tuikuan-mianxing',
  },
]

// 主题色
// 开关切换两套主题
// window.onload = function setAttributeDataTheme () {
//   let element = document.querySelector('[data-theme]')
//   let flag = localStorage.getItem("theme") == "dark"
//   if(flag) {
//     element.setAttribute('data-theme', 'dark')
//   }
//   // localStorage.setItem('theme', 'default')
// };





// // tabBar配置
// let tabBarListFlag = localStorage.getItem('configThemetabBar') 
// switch (tabBarListFlag) {
//   case '1':
//     var tabBarList = [
//       {
//         path: "/index",
//         name: "首页1",
//         icon: 'icon-shouye1',
//       },
//        {
//         path: "/classFication",
//         name: "分类",
//         icon: 'icon-fenlei4',
//       },
//        {
//         path: "/shoppingcar",
//         name: "购物车",
//         icon: 'icon-gouwuche3',
//       },
//       {
//         path: "/my",
//         name: "我的",
//         icon: 'icon-wode-',
//       }
//     ];
//     break;
//   case '2':
//     var tabBarList = [
//       {
//         path: "/index",
//         name: "首页2",
//         icon: 'icon-shouye',
//       },
//        {
//         path: "/classFication",
//         name: "分类",
//         icon: 'icon-gongnengkaiguan',
//       },
//        {
//         path: "/shoppingcar",
//         name: "购物车",
//         icon: 'icon-gouwuche',
//       },
//       {
//         path: "/my",
//         name: "我的",
//         icon: 'icon-xingming',
//       }
//     ];
//     break;
//   default:
//     var tabBarList = [
//       {
//         path: "/index",
//         name: "首页",
//         icon: 'icon-shouye1',
//       },
//        {
//         path: "/classFication",
//         name: "分类",
//         icon: 'icon-fenlei4',
//       },
//        {
//         path: "/shoppingcar",
//         name: "购物车",
//         icon: 'icon-gouwuche3',
//       },
//       {
//         path: "/my",
//         name: "我的",
//         icon: 'icon-wode-',
//       }
//     ];
//     break;
// }
// icon-51 icon-fenlei icon-gouwuche5 icon-weibiaoti2fuzhi12 icon-gongnengkaiguan icon-gouwuche icon-wodechanpinku


// finished-text加载完成后的提示文案





// 我的头部背景图片
// var myHeadBackgroundImg = require("@/assets/images/my-bk.png")



// function setAttributeDataTheme(theme) {
//   var element = document.querySelector('[data-theme]')
//   element.setAttribute('data-theme', theme)
// };
export default {
  redirectuUrl,
  logoTitle,
  finishedText,
  loadingInfinityImg,
  orderList,
  // tabBarList,
  // myHeadBackgroundImg,
  // setAttributeDataTheme
}